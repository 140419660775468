import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
// import logger from "redux-logger";
import RootReducer from "./reducers/RootReducer"


// export const store = createStore(
//     RootReducer,
//     compose(
//       applyMiddleware(thunk),
//       window.__REDUX_DEVTOOLS_EXTENSION__ &&
//         window.__REDUX_DEVTOOLS_EXTENSION__(),
//     ),
// );


//fix für redux und firefox:

export const middleware = applyMiddleware(
	thunk,
	// thunkMiddleware,
	// authStateMiddleware
);

// if(process.env.NODE_ENV === "development") {
// 	middleware.push(logger);
// }

export const composeEnhancers =
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
	RootReducer,
	composeEnhancers(middleware)
);

