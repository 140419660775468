import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from "./components/pages/HomeComponent";
import AboutUs from "./components/pages/AboutUsComponent";
import Vorstand from "./components/pages/VorstandComponent";
import Team from "./components/pages/TeamComponent";
import Innungen from "./components/pages/InnungenComponent";
import Aktuelles from "./components/pages/AktuellesComponent";
import Seminare from "./components/pages/SeminareComponent";
import Jobs from "./components/pages/JobsComponent";
import Datenschutz from "./components/pages/DatenschutzComponent";
import Impressum from "./components/pages/ImpressumComponent";

function Routes() {
    return (
        <>
            <Switch>
                <Route exact path="/" render={props => (
                    <Redirect to={{ pathname: '/home' }} />
                )} />

                <Route path="/home" component={Home} />
                <Route path="/ueber_uns" component={AboutUs} />
                <Route path="/vorstand" component={Vorstand} />
                <Route path="/team" component={Team} />
                <Route path="/innungen" component={Innungen} />
                <Route path="/aktuelles" component={Aktuelles} />
                <Route path="/seminare" component={Seminare} />
                <Route path="/jobs" component={Jobs} />
                <Route path="/datenschutz" component={Datenschutz} />
                <Route path="/impressum" component={Impressum} />

                <Route exact path="*" render={props => (
                    <Redirect to={{ pathname: '/home' }} />
                )} />

            </Switch>
        </>
    );
}

export default Routes;
