import React from 'react';
// import React, { useEffect } from 'react';
// import Button from '@mui/material/Button';
// import { useStyles } from '../../styles/styles';
// import { useHistory } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import DocumentMeta from 'react-document-meta';

function VorstandComponent() {

    // const history = useHistory();

    // const classes = useStyles();

    // const gotToDashboard = (e, url) => {
    //   history.push(url);
    // }

    const meta = {
        title: 'Vorstand - Kreishandwerkerschaft Emmendingen',
        description: '',
        // canonical: 'http://example.com/path/to/page',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'kreishandwerkerschaft, emmendingen, kreishandwerkerschaft emmendingen'
            }
        }
    };

    return (
        <DocumentMeta {...meta}>
            <div className="container mt-2 mb-2">
                <h1 className="mt-2 mb-3">Vorstand der Kreishandwerkerschaft Emmendingen</h1>
                {/* <img className="img-fluid m-auto" src="/img/vorstand/vorstandKHEM.jpeg" alt="Vorstand Kreishandwerkerschaft Emmendingen" /> */}
            </div>
            <div className="container mt-3 mb-2">

                <div className="grid grid--two-to-one">

                    <div className="">
                        <h3 className="mt0">Kreishandwerksmeister</h3>
                        <div className="">
                            <p>
                                <strong>Martin Schubnell</strong><br />
                                Obermeister der Elektro- und Informationstechnik Innung<br /><br />
                                Kreuzmattenstraße 21<br />
                                79276 Reute<br />
                                Tel.: 07641 &#8211; 1863<br />
                                Fax: 07641 &#8211; 571796<br />
                                Mail: <a href="mailto:info@schubnell-der-elektriker.de">info@schubnell-der-elektriker.de</a>
                            </p>
                        </div>
                    </div>
                    <div className="">
                        <img className="img-fluid" src="/img/vorstand/schubnell.jpeg" alt="Martin Schubnell" />
                    </div>

                    <div className="mt-2">
                        <h3 className="mt0">Stellvertretender Kreishandwerksmeister</h3>
                        <div className="">
                            <p>
                                <strong>Christof Burger</strong><br />
                                Obermeister der Baugewerk-Innung<br /><br />
                                Adalbert Stifter Str. 2<br />
                                79183 Waldkirch<br />
                                Tel.: 07681 &#8211; 477850<br />
                                Fax: 07681 &#8211; 4778525<br />
                                Mail: <a href="mailto:technik@karl-burger.de">technik@karl-burger.de</a>
                            </p>
                        </div>
                    </div>
                    <div className="mt-2">
                        <img className="img-fluid" src="/img/vorstand/christofburger.jpeg" alt="Christof Burger" />
                    </div>

                    <div className="mt-2">
                        <h3 class="mt0">Beisitzer / Kassierer</h3>
                        <div className="">
                            <p>
                                <strong>Hubert Becherer</strong><br />
                                Obermeister Sanitär- Heizung-Klima Innung Emmendingen<br /><br />
                                Hauptstr. 67<br />
                                79215 Elzach<br />
                                Tel.: 07682 &#8211; 9269966<br />
                                Fax: 07682 &#8211; 9269967<br />
                                Mail: <a href="mailto:mail@hubert-becherer.de">mail@hubert-becherer.de</a>
                            </p>
                        </div>
                    </div>
                    <div className="mt-2">
                    </div>

                    <div className="mt-2">
                        <h3 class="mt0">Beisitzer</h3>
                        <div className="">
                            <p>
                                <strong>Werner Böcherer</strong><br />
                                Obermeister der Zimmerer-Innung<br /><br />
                                Denzlinger Str. 11<br />
                                79350 Sexau<br />
                                Tel.: 07641 &#8211; 9584416<br />
                                Fax: 07641 &#8211; 9184417<br />
                                Mail: <a href="mailto:info@boecherer.eu">info@boecherer.eu</a>
                            </p>
                        </div>
                    </div>
                    <div className="mt-2">
                    </div>

                    <div className="mt-2">
                        <h3 className="mt0">Beisitzer</h3>
                        <div className="">
                            <p>
                                <strong>Tobias Limberger</strong><br />
                                Obermeister der Maler- und Lackierer-Innung<br /><br />
                                Carl-Helbig Str. 22<br />
                                79312 Emmendingen<br />
                                Tel.: 07641 &#8211; 42381<br />
                                Fax: 07641 &#8211; 42425<br />
                                Mail: <a href="mailto:tobias-limberger@gmx.de">tobias-limberger@gmx.de</a>
                            </p>
                        </div>
                    </div>
                    <div className="mt-2">
                        <img className="img-fluid" src="/img/vorstand/tobiaslimberger.jpeg" alt="Tobias Limberger" />
                    </div>

                </div>
            </div>
        </DocumentMeta>
    )
}

export default VorstandComponent