import React from 'react';
// import React, { useEffect } from 'react';
// import Button from '@mui/material/Button';
// import { useStyles } from '../../styles/styles';
// import { useHistory } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import jsonData from '../../innungen.json';

function InnungenComponent(props: any) {

    // const history = useHistory();

    // const classes = useStyles();

    const meta = {
        title: 'Innungen - Kreishandwerkerschaft Emmendingen',
        description: '',
        // canonical: 'http://example.com/path/to/page',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'kreishandwerkerschaft, emmendingen, kreishandwerkerschaft emmendingen'
            }
        }
    };

    return (
        <DocumentMeta {...meta}>
            <div className="container mt-5">
                <h1 className="mt-2 mb-3">Innungen</h1>
                <div className="grid grid--two-columns-even grid-gap-xl">
                    {jsonData.innungen.map((row: any) => (
                        <div className="innung grid grid--one-to-two grid-gap-xs">
                            <div className="innung__image">
                                <img className="img-fluid" src={row.image} alt={row.innung} />
                            </div>
                            <div className="innung__content">
                                <h4 className="mt0">{row.innung}</h4>
                                <b>{row.name}</b><br />
                                {row.bez}<br />
                                {row.ort}<br />
                                {row.link ?
                                    <a href={row.link} className="button button--primary" target="blanc">{row.linkName}</a>
                                    : <></>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </DocumentMeta>
    )
}

export default InnungenComponent
