import React from 'react';
import DocumentMeta from 'react-document-meta';

function AktuellesComponent() {

    const meta = {
        title: 'Aktuelles - Kreishandwerkerschaft Emmendingen',
        description: '',
        // canonical: 'http://example.com/path/to/page',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'kreishandwerkerschaft, emmendingen, kreishandwerkerschaft emmendingen'
            }
        }
    };

    return (
        <DocumentMeta {...meta}>

            <div className="container">
                <div>
                    <h1 className="mt-3 mb-2">Maibaum in Emmendingen am Marktplatz</h1>
                    <div className="grid grid--two-columns-even">
                        <div>
                            <img className="" alt="" src="/img/maibaum_01-04811fbd.jpeg" />
                        </div>
                        <div>
                            <p>Am Dienstag, 30.04.2024, um 17:00 Uhr wird die Kreishandwerkerschaft Emmendingen
                                den Handwerker-Maibaum auf dem Markplatz in Emmendingen wieder aufstellen.</p>
                                <br />
                            <p>Alle Bürgerinnen und Bürger sind zur Maibaum-Veranstaltung herzlich eingeladen. Für
                                das leibliche Wohl ist gesorgt.</p>
                        </div>
                    </div>

                    <h2 className="mt-3 mb-2">Terminvorschau 2024</h2>

{/* 
                    <div className="grid grid--four-columns mb-2 grid-aktuelles">
                        <div>
                            Mittwoch, 31.01.2024
                        </div>
                        <div>
                            Jahreshauptversammlung
                        </div>
                        <div>
                            Stammhaus Riegel
                        </div>
                        <div>
                            18:30 Uhr
                        </div>
                    </div>

                    <div className="grid grid--four-columns mt-3 mb-2 grid-aktuelles">
                        <div>
                        Mittwoch, 24.04.2024	
                        </div>
                        <div>
                        Frühjahrsfreisprechung
                        </div>
                        <div>
                        Elzhalle / Emmendingen-Wasser
                        </div>
                        <div>
                            19:30 Uhr
                        </div>
                    </div>

                    <div className="grid grid--four-columns mt-3 mb-2 grid-aktuelles">
                        <div>
                        Dienstag, 30.04.2024
                        </div>
                        <div>
                        Maibaum stellen
                        </div>
                        <div>
                            Marktplatz Emmendingen
                        </div>
                        <div>
                        17:00 Uhr
                        </div>
                    </div>

                    <div className="grid grid--four-columns mt-3 mb-2 grid-aktuelles">
                        <div>
                        Donnerstag, 29.05.2024
                        </div>
                        <div>
                        Maibaum abbauen
                        </div>
                        <div>
                        Marktplatz Emmendingen
                        </div>
                        <div>
                        08:30 Uhr
                        </div>
                    </div>

                    <div className="grid grid--four-columns mt-3 mb-2 grid-aktuelles">
                        <div>
                        Freitag, 12.07.2024
                        </div>
                        <div>
                            Golfturnier
                        </div>
                        <div>
                        Golfclub Bleibach
                        </div>
                        <div>
                            14:00 Uhr
                        </div>
                    </div>
 */}

                    <div className="grid grid--four-columns mt-3 mb-2 grid-aktuelles">
                        <div>
                        Samstag, 21.09.204
                        </div>
                        <div>
                            Tag des Handwerks / Startschuss Meisternetzwerk
                        </div>
                        <div>
                        Rathaus Emmendingen
                        </div>
                        <div>
                        16:00 Uhr
                        </div>
                    </div>

                    <div className="grid grid--four-columns mt-3 mb-2 grid-aktuelles">
                        <div>
                        Mittwoch, 25.09.2024
                        </div>
                        <div>
                            Herbstfreisprechungsfeier
                        </div>
                        <div>
                            Elzhalle Emmendingen-Wasser
                        </div>
                        <div>
                            19:30 Uhr
                        </div>
                    </div>

                    <div className="grid grid--four-columns mt-3 mb-2 grid-aktuelles">
                        <div>
                        Sonntag, 20.10.2024
                        </div>
                        <div>
                        Altmeisterehrung
                        </div>
                        <div>
                        Gemeindehaus St. Bonifatius EM
                        </div>
                        <div>
                        11:00 Uhr
                        </div>
                    </div>

                    <div className="grid grid--four-columns mt-3 mb-2 grid-aktuelles">
                        <div>
                        Freitag, 25.10.2024
                        </div>
                        <div>
                        70 Jahr Feier
                        </div>
                        <div>
                        Franz-Xaver Hof Buchholz
                        </div>
                        <div>
                        17:30 Uhr 
                        </div>
                    </div>

                    <div className="grid grid--four-columns mt-3 mb-2 grid-aktuelles">
                        <div>
                        Mittwoch, 06.11.2024
                        </div>
                        <div>
                        Mitgliederversammlung
                        </div>
                        <div>
                            
                        </div>
                        <div>
                            19:00 Uhr
                        </div>
                    </div>



                    {/* <div>

            <div>
              <div>
                <div>
                  <p>Montag<br />25.05.2020</p>
                </div>
              </div>
              <div>
                <div>
                  <p><br />Maibaum abbauen</p>
                </div>
              </div>
              <div>
                <div>
                  <p><br />Marktplatz Emmendingen</p>
                </div>
              </div>
              <div>
                <div>
                  <p><br />08.30 Uhr</p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <p>Samstag<br />19.09.2020</p>
                </div>
              </div>
              <div>
                <div>
                  <p><br />Tag des Handwerks</p>
                </div>
              </div>
              <div>
                <div>
                  <p><br />Elzach</p>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <p>Mittwoch<br />23.09.2020</p>
                </div>
              </div>
              <div>
                <div>
                  <p><br />Herbstfreisprechungsfeier</p>
                </div>
              </div>
              <div>
                <div>
                  <p><br />Elzhalle Emmendingen-Wasser</p>
                </div>
              </div>
              <div>
                <div>
                  <p><br />19.30 Uhr</p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <p>Mittwoch<br />14.10.2020</p>
                </div>
              </div>
              <div>
                <div>
                  <p><br />Jahreshauptversammlung</p>
                </div>
              </div>
              <div>
                <div>
                  <p><br />HWH Emmendingen</p>
                </div>
              </div>
              <div>
                <div>
                  <p><br />19.00 Uhr</p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <p>Sonntag<br />08.11.2020</p>
                </div>
              </div>
              <div>
                <div>
                  <p><br />Altmeisterehrung</p>
                </div>
              </div>
              <div>
                <div>
                  <p><br />HWH Emmendingen</p>
                </div>
              </div>
              <div>
                <div>
                  <p><br />11.00 Uhr</p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <p><strong>**** Bauferien lt. Bauwirtschaft vorgeschlagen vom
                    03.08.2020 &#8211; 22.08.2020 ****</strong></p>
                </div>
              </div>
            </div>
          </div> */}
                </div>
            </div>
        </DocumentMeta>
    )
}

export default AktuellesComponent