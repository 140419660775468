import React from 'react';
import DocumentMeta from 'react-document-meta';

function SeminareComponent() {

    const meta = {
        title: 'Seminare - Kreishandwerkerschaft Emmendingen',
        description: '',
        // canonical: 'http://example.com/path/to/page',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'kreishandwerkerschaft, emmendingen, kreishandwerkerschaft emmendingen'
            }
        }
    };

    return (
        <DocumentMeta {...meta}>

            <div className="container">
                <div>
                    <h1 className="mt-3 mb-2">Seminar Programm “Führungswerkstatt” von unserem Kooperationspartner IKK classic</h1>
                    <div className="grid grid--two-columns-even">
                        <div>
                            <img className="" alt="" src="/img/image005a.jpg" />
                        </div>
                        <div>
                          <p>mit dem Seminarprogramm &ldquo;F&uuml;hrungswerkstatt&rdquo; spricht die IKK classic Fachkr&auml;fte an, die auf dem Weg zur F&uuml;hrungskraft sind oder bereits F&uuml;hrungsverantwortung &uuml;bernommen haben.</p>


                          <p>Mit der F&uuml;hrungswerkstatt geben wir alle Tools an die Hand, um ihr Team kompetent zu leiten. Von A wie Azubis finden bis Z wie Zielgespr&auml;che f&uuml;hren: in mehreren interaktiven Seminarmodulen mit lockerer Workshop-Atmosph&auml;re arbeiten die Teilnehmenden an Ihren F&uuml;hrungsqualit&auml;ten. Zus&auml;tzlich erhalten sie Lernvideos, Arbeitsbl&auml;tter und Checklisten, die sie auf ihrem Weg zur Top-F&uuml;hrungskraft unterst&uuml;tzen.</p>
                        </div>
                    </div>

                    <h2 className="mt-3 mb-2">Folgende Termine sind buchbar:</h2>

                    <ul>
                      <li>Modul &ldquo;Starke F&uuml;hrung &ndash; klare Kommunikation&rdquo;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <ul>
                        <li>22.10.2024: 14:00 &ndash; 16:00 Uhr</li>
                        <li>05.12.2024: 10:00 &ndash; 12:00 Uhr (Alternativtermin)</li>
                      </ul>
                      </li>
                    </ul>

                    <p>&nbsp;</p>

                    <ul>
                      <li>Modul &ldquo;Souver&auml;n ist das neue Cool&rdquo;:
                      <ul>
                        <li>07.11.2024: 16:00 &ndash; 18:00 Uhr</li>
                        <li>12.11.2024: 14:00 &ndash; 16:00 Uhr (Alternativtermin)</li>
                      </ul>
                      </li>
                    </ul>

                    <p>&nbsp;</p>

                    <ul>
                      <li>Modul &ldquo;Talentwerker I&rdquo; &ndash; In 7 Schritten zum perfekten Team:
                      <ul>
                        <li>12.11.2024: 10:00 &ndash; 12:00 Uhr</li>
                        <li>25.11.2024: 10:00 &ndash; 12:00 Uhr (Alternativtermin)</li>
                      </ul>
                      </li>
                    </ul>

                    <p>&nbsp;</p>

                    <ul>
                      <li>Modul &ldquo;Talentwerker II&rdquo; &ndash; Attraktiv f&uuml;r Azubis:
                      <ul>
                        <li>04.12.2024: 14:00 &ndash; 16:00 Uhr</li>
                        <li>10.12.2024: 13:30 &ndash; 15:30 Uhr</li>
                      </ul>
                      </li>
                    </ul>

                    <p><br />
                    <strong>IKK classic-App: Digitale Vorteile nutzen.</strong><br />
                    Ab sofort den IKK Bonus ganz einfach &uuml;ber die IKK classic-App sichern. Jetzt kostenfrei im <a href="https://www.ikk-classic.de/pk/sp/apps/ikk-classic" target="_blanc">App-Store</a>&nbsp;herunterladen!<br />
                    <br />
                    IKK classic &ndash; Ihre Gesundheit. Unser Handwerk.<br />
                    <br />                    
                    </p>


             
                </div>
            </div>
        </DocumentMeta>
    )
}

export default SeminareComponent