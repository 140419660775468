import React, { useEffect } from "react";

function CookieDeclaration(props: any) {

  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://consent.cookiebot.com/96e97c27-353d-41a5-b05a-0aab04464211/cd.js";
    script.async = true;
    script.id = "CookieDeclaration";
    script.dataset.culture = "de";  //create a data attribute, or use script.setAttribute("data-culture", "de");
  
    const el = document.getElementById('CookieDeclarationWrapper');
    if(el) {
      el.appendChild(script);
    }
  
    return () => {
      const el = document.getElementById('CookieDeclarationWrapper');
      if(el) {
        el.removeChild(script);
      }      
    }
  }, []);

  return (
    <>
      <h2>Informationen zu den eingesetzten Cookies</h2>
      <div className="mt-2" id="CookieDeclarationWrapper"></div>
    </>
  );
}

export default CookieDeclaration;