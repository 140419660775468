import React from 'react';
// import React, { useEffect } from 'react';
// import { useStyles } from '../../styles/styles';
// import { useHistory } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import DocumentMeta from 'react-document-meta';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../scss/slick_custom.scss';

import Slider from "react-slick";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <svg className="icon" aria-labelledby="angle-right-light" role="img">
                <title id="angle-right-light">next</title>
                <use xlinkHref="#angle-right-light" />
            </svg>
        </div>
        // <div
        //   className={className}
        //   style={{ ...style, display: "block", background: "red" }}
        //   onClick={onClick}
        // />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <svg className="icon" aria-labelledby="angle-left-light" role="img">
                <title id="angle-left-light">prev</title>
                <use xlinkHref="#angle-left-light" />
            </svg>
        </div>
        // <div
        //   className={className}
        //   style={{ ...style, display: "block", background: "green" }}
        //   onClick={onClick}
        // />
    );
}

function HomeComponent() {

    // const history = useHistory();

    // const classes = useStyles();

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const meta = {
        title: 'Home - Kreishandwerkerschaft Emmendingen',
        description: '',
        // canonical: 'http://example.com/path/to/page',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'kreishandwerkerschaft, emmendingen, kreishandwerkerschaft emmendingen'
            }
        }
    };

    return (
        <DocumentMeta {...meta}>
            <div className="container">
                <div className="mt-2 mb-2">
                    <Slider {...settings}>
                        <div>
                            <img className="slick-img" src="/img/slides/160318_HW_Twitter_Time_Line_Lackierer_L-bd48c829.png" alt="" />
                        </div>
                        <div>
                            <img className="slick-img" src="/img/slides/160318_HW_Twitter_Time_Line_Stuckateur_L-8b1582c6.png" alt="" />
                        </div>
                        <div>
                            <img className="slick-img" src="/img/slides/160318_HW_Twitter_Time_Line_Tischler_L-75f7e497.png" alt="" />
                        </div>
                    </Slider>
                </div>

                <h1 className="mt-3 mb-2">Herzlich Willkommen bei der Kreishandwerkerschaft Emmendingen</h1>
                <p>Die Kreishandwerkerschaft versteht sich als Dienstleister für ihre Mitglieder und die ihr angeschlossenen Innungsbetriebe. Sie vertritt die Gesamtinteressen des Handwerks im Landkreis Emmendingen und die Interessen der angeschlossenen Innungen.</p>
                <p>Die Kreishandwerkerschaft und die angeschlossenen Innungen sind ein wichtiger Partner für die angeschlossenen Betriebe und stehen den Betrieben mit Rat und Tat zur Seite.</p>
                <div className="mt-2 mb-2 text-center ">
                    <video controls height="420" width="750">
                        <source src="/undwashastdu.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>
        </DocumentMeta>
    )
}

export default HomeComponent