import React from 'react';
import DocumentMeta from 'react-document-meta';

function JobsComponent() {

    const meta = {
        title: 'Jobs - Kreishandwerkerschaft Emmendingen',
        description: '',
        // canonical: 'http://example.com/path/to/page',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'kreishandwerkerschaft, emmendingen, kreishandwerkerschaft emmendingen'
            }
        }
    };

    return (
        <DocumentMeta {...meta}>
            <div className="container">
                <div>

                    <div>
                        <div>
                            <div>
                                <div>
                                    <h1 className="mt-3 mb-2">Fachkräfte </h1>
                                    <div><a href="#ls">(Direkt zu den Lehrstellen)</a></div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <p><strong>Elektro-Informationstechnik</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <h4> Stellenbeschreibung
                                                    </h4>
                                                    <div>
                                                        <p>Elektroniker/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h4> Anforderung </h4>
                                                    <div>
                                                        <p>Geselle Elektrotechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h4> Betrieb </h4>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Martin Schubnell Der-Elektriker</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Kreuzmattenstr. 21</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79276 Reute</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.schubnell-der-elektriker.de">www.schubnell-der-elektriker.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail:<a href="mailto:info@schubnell-der-elektriker.de">
                                                                        info@schubnell-der-elektriker.de</a></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07641-1863</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h4> Beginn </h4>
                                                    <div>
                                                        <p>01.06.2019</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h4> Vollzeit/Teilzeit
                                                    </h4>
                                                    <div>
                                                        <p>40 Stunden/Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle Elektrotechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Bergmann Elektrosysteme</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Schochenwinkel 8</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79353 Bahlingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.bergmann-elektrosysteme.de/">www.bergmann-elektrosysteme.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="mailto:info@bergmann-elektrosysteme.de">info@bergmann-elektrosysteme.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>07663 6056150</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ab sofort</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Vollzeit</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in (Geselle)</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle Elektrotechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Bockstahler Elektroanlagen</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ab sofort</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>40 Stunden/Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in (Meister)</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Meister Elektrotechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">B.Eng. Benjamin Bockstahler</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dominik-Weber-Straße 14</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79312 Emmendingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.bockstahlerelektro.de">www.bockstahlerelektro.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@bockstahlerelektro.de">info@bockstahlerelektro.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel.: 07641-2244</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ab sofort</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>40 Stunden/Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle Elektrotechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Braun Elektro GmbH &amp; Co.KG</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>August Jeanmaire Straße 16</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79183 Waldkirch</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a href="http://www.elektrobraun.com">www.elektrobraun.com</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@elektrobraun.com">info@elektrobraun.com</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07681-7890</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ab sofort</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>39 Stunden/Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in<br />Es werden 2 Leute benötigt</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle Elektrotechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Meike Elektrotechnik OHG</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Gottlieb-Daimler-Str. 13</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79211 Denzlingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a href="http://www.elektro-meike.de">www.elektro-meike.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a href="mailto:info@elektro-meike.de">info@elektro-meike.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel. 07666-900 445</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ab sofort</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>40 Stunden/Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle Elektrotechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Buderer Elektrotechnik</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Hans-Theisen-Str. 5</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79331 Teningen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.elektro-buderer.de">www.elektro-buderer.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@elektro-buderer.de">info@elektro-buderer.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07641-51583</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>sofort / nach Absprache</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>40 Stunden/Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle Elektrotechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Billharz Energiesysteme GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Am Stollen 4</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79261 Gutach</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.billharz-energiesyteme.de">www.billharz-energiesyteme.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@billharz-energiesysteme.de">info@billharz-energiesysteme.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07685/9083450</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.04.2019</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>37,5 Stunden/ Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle Elektrotechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Elektro Schmidt</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Hauptstr. 138</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79211 Denzlingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.elektro-schmidt.biz">www.elektro-schmidt.biz</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="mailto:info@elektro-schmidt.biz">info@elektro-schmidt.biz</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel. 07666/2352</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.06.2019</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>40 Stunden/ Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in<br />Energie- und Gebäudetechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle Elektroniker/in<br />Energie- und Gebäudetechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">ETAK Elektrounternehmen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Elsässer Straße 15</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79346 Endingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail:<a href="mailto:info@etak-elektro.de">
                                                                        info@etak-elektro.de</a></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07642 907790</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>sofort</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>40 Stunden/ Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle/in Fachrichtung<br />Energie- und Gebäudetechnik<br />Kleine
                                                            Installationen,<br />Reparaturen<br />Gerätemessungen</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Elektro Nefzger GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Kohlerhof 8</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79211 Denzlingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07666-6430</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.elektro-nefzger.de">www.elektro-nefzger.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:praeg@elektro-nefzger.de">praeg@elektro-nefzger.de</a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.04.2019</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>37 Stunden/ Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div id="Link Gipser- und Stuckateur">
                                        <p><strong>Gipser- und Stukkateur</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Stuckateur/in<br />Trockenbauer/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Facharbeiter</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Fa. Waibel GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Putz &#8211; Stuck &#8211; Trockenbau</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Rudolf Blessing Strasse 1</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79183 Waldkirch</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@waibel-putz.de">info@waibel-putz.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07681/6351</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.05.2019</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>40 Stunden/Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Gipser-Stuckateur</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Facharbeiter oder<br />Arbeiter ohne Prüfung</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Fa. Männer GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Schäppeleweg 13</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79110 Freiburg</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="mailto:sigrun.zipse@maenner-stucketuer.de">sigrun.zipse@maenner-stucketuer.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 0761-8924588</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.04.2019</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>40 Stunden/Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Stuckateur/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle im Stuckateur-<br />Handwerk</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Urban Dettinger</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Gewerbestraße 3</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79183 Waldkirch-Kollnau</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.dettinger-putz-stuck.de">www.dettinger-putz-stuck.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>E-Mail:<a href="mailto:info@dettinger-putz-stuck.de">
                                                                        info@dettinger-putz-stuck.de</a></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel.: 07681 7847</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Handy: 0170 4425571</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>sofort</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>40 Stunden/Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Stuckateur</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle Stuckateur</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Volker Weismann<br />Fachbetrieb für Ausbau<br />und Fassade
                                                            e.K.<br />Biberwiese 6<br />79822 Titisee-Neustadt<br />Mail: <a
                                                                href="mailto:info@weismann-volker.de">info@weismann-volker.de</a><br />Tel:
                                                            07651/1219</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>sofort</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>40 Stunden/Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <p><strong>Metallbau</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Schweißer<br /><br />Kanter</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>WIG/MAG</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Keller Blechtechnik GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Wöllinger Straße 3 + 5</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79346 Endingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Ansprechpartner: Antje Keller</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="mailto:antje.keller@keller-blechtechnik.de">antje.keller@keller-blechtechnik.de</a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>nach Vereinbarung<br /><br />nach Vereinbarung</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>40 Stunden/Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Metallbauer/Schlosser</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle Metallbau</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Schüber Metalltechnik GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tullastr. 24</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79369 Wyhl</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a href="http://www.schueber-gmbh.de">www.schueber-gmbh.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>mail: <a
                                                                        href="mailto:heike.schueber@schueber-gmbh.de">heike.schueber@schueber-gmbh.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>07642/926090</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.04.2019</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>40 Stunden/Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Metallbauer/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle Metallbau</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Metallwerkstatt Heilmann GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Kastelbergstr.19a</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79183 Waldkirch</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.metallwerkstatt-heilmann.de">www.metallwerkstatt-heilmann.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="mailto:info@metallwerkstatt-heilmann.de">info@metallwerkstatt-heilmann.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>07681/4977295</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ab sofort</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>40 Stunden/Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <p><strong>Friseur</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Friseur/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Salon Kern</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Inh. Petra Ketterer</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Stuttgarter Str. 1</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79211 Denzlingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a href="mailto:kettererpetra@web.de">kettererpetra@web.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>07666-3043</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>sofort</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>37,5 Stunden/Woche<br />Teilzeit auf 450,00 €</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Friseur/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle/Meister</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Gutjahr Hairdream GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Landvogtei 6</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79312 Emmendingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.gutjhar-hairdream.de/">www.gutjhar-hairdream.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:gutjahr-hairdream@t-online.de">gutjahr-hairdream@t-online.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07641-1000</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.04.2019</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>39,5 Stunden/Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Friseur/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle/Meister<br /><br />mit Option der Geschäftsübernahme</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Friseur Fehrenbach</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Martina Dorer</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dorfstr.:62</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79261 Gutach Bleibach</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:martina.dorer@gmx.de">martina.dorer@gmx.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 0170 4643762</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ab sofort</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Vollzeit/Teilzeit</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <p><strong>Baugewerbe</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Maurer oder
                                                            Stahlbetonbauer<br />Zimmermann<br />Maurermeister<br />Zimmerermeister<br />Bautechniker<br />Straßenbaumeister<br />Dipl.-Ing.
                                                            Bau mit<br />Berufserfahrung oder<br />zus. Dualer Ausbildung</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Ausbildung oder <br />entsprechende<br />Berufserfahrung</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Karl Burger Hoch-, Tief- und Holzbau</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Adalbert-Stifter-Strasse 2</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79183 Waldkirch</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@karl-burger.de">info@karl-burger.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Ansprechpartner: Silvia Burger</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel.: 07681-47785-11 oder 07681-47785-0</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>sofort oder<br />nach Absprache</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Vollzeit, evtl. Teilzeit möglich<br />Regelungen und Vergütung
                                                            <br />gemäß BRTV Baugewerbe</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <p><strong>Fleischer/Verkäufer</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Fleischer/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Geselle Fleischer<br />(Schlachtung/Zerlegung)</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="258">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="258">Gustav Winterhalter GmbH </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Telferstraße 4</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Elzach</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="mailto:info@metzgerei-winterhalter.de">info@metzgerei-winterhalter.de</a>
                                                                        / 07682/91110</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.06.2019</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>40 Stunden/Woche</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <h1> <a name="ls" id="ls">Lehrstellen</a> </h1>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <p><strong>Elektrotechnik</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <h3> Ausbildung </h3>
                                                    <div>
                                                        <p>Elektroniker/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h3> Betrieb </h3>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Martin Schubnell der-elektriker</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Kreuzmattenstr. 21</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79276 Reute</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.schubnell-der-elektriker.de/">www.schubnell-der-elektriker.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@schubnell-der-elektriker.de">info@schubnell-der-elektriker.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07641-1863</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h3> Lehrzeit </h3>
                                                    <div>
                                                        <p>3,5 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h3> Lehrbeginn </h3>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h3>
                                                        Praktikumsmöglichkeit </h3>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Bergmann Elektrosysteme</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Schochenwinkel 8</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79353 Bahlingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.bergmann-elektrosysteme.de/">www.bergmann-elektrosysteme.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="mailto:info@bergmann-elektrosysteme.de">info@bergmann-elektrosysteme.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>07663 6056150</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3,5 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja<br />ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Bockstahler Elektroanlagen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>B.Eng. Benjamin Bockstahler</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dominik-Weber-Straße 14</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79312 Emmendingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.bockstahlerelektro.de">www.bockstahlerelektro.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@bockstahlerelektro.de">info@bockstahlerelektro.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel.: 07641-2244</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3,5 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in<br />Informationselektroniker/-in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Braun Elektro GmbH &amp; Co.KG</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>August Jeanmaire Straße 16</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79183 Waldkirch</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a href="http://www.elektrobraun.com">www.elektrobraun.com</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@elektrobraun.com">info@elektrobraun.com</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07681-7890</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3,5 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Meike Elektrotechnik OHG</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Gottlieb-Daimler-Str. 13</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79211 Denzlingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a href="http://www.elektro-meike.de">www.elektro-meike.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a href="mailto:info@elektro-meike.de">info@elektro-meike.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel. 07666-900 445</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3,5 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in<br />Energie- u. Gebäudtechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Buderer Elektrotechnik</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Hans-Theisen-Str. 5</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79331 Teningen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.elektro-buderer.de">www.elektro-buderer.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@elektro-buderer.de">info@elektro-buderer.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07641-51583</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3,5 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in<br />für Betriebstechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Spöri &amp; Gerber Elektrotechnik GmbH &amp; Co.
                                                                        KG</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Hauptstr. 70</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79312 Emmendingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.spoeriundgerber.de">www.spoeriundgerber.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@spoeriundgerber.de">info@spoeriundgerber.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>07641-92990</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3,5 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Billharz Energiesysteme GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Am Stollen 4</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79261 Gutach</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.billharz-energiesysteme.de">www.billharz-energiesysteme.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@billharz-energiesysteme.de">info@billharz-energiesysteme.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07685/9083450</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3,5 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Elektro Schmidt</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Hauptstr. 138</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79211 Denzlingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.elektro-schmidt.biz">www.elektro-schmidt.biz</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="mailto:info@elektro-schmidt.biz">info@elektro-schmidt.biz</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel. 07666/2352</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3,5 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Elektroniker/in<br />Energie- und Gebäudetechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">ETAK Elektrounternehmen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Elsässer Straße 15</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79346 Endingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@etak-elektro.de">info@etak-elektro.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07642 907790</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3,5 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div id="Link Gipser- und Stuckateur">
                                        <p><strong>Gipser- und Stukkateur</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Stuckateur/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Fa. Waibel GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Putz &#8211; Stuck &#8211; Trockenbau</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Rudolf Blessing Strasse 1</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79183 Waldkirch</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@waibel-putz.de">info@waibel-putz.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07681/6351</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja<br />ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Gipser-Stuckateur</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Fa. Männer GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Schäppeleweg 13</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79110 Freiburg</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="mailto:sigrun.zipse@maenner-stucketuer.de">sigrun.zipse@maenner-stucketuer.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 0761-8924588</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Stuckateur/in<br />Gipser/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">H. Wurst Stuckateurbetrieb GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Gewerbestraße 8</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79364 Malterdingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.wurst-stuckateur.de">www.wurst-stuckateur.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@wurst-stuckateur.de">info@wurst-stuckateur.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 076448693</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Stuckateur/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Urban Dettinger</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Gewerbestraße 3</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79183 Waldkirch-Kollnau</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.dettinger-putz-stuck.de">www.dettinger-putz-stuck.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>E-Mail: <a
                                                                        href="mailto:info@dettinger-putz-stuck.de">info@dettinger-putz-stuck.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel.: 07681 7847</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Handy: 0170 4425571</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <p><strong>Friseur</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Friseur/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Friseur Akademie Freiburg</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Bismarckallee 9</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79098 Freiburg</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>0761-29095347</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@friseur-einkauf.com">info@friseur-einkauf.com</a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.08.2019</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Friseur/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Gutjahr Hairdream GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Landvogtei 6</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79312 Emmendingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.gutjhar-hairdream.de/">www.gutjhar-hairdream.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:gutjahr-hairdream@t-online.de">gutjahr-hairdream@t-online.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07641-1000</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Friseur/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Friseur Fehrenbach</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Martina Dorer</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dorfstr.:62</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79261 Gutach Bleibach</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:martina.dorer@gmx.de">martina.dorer@gmx.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 0170 4643762</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <p><strong>Metallbau</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Metallbauer/in<br />Konstruktionstechnik<br /><br />Anzahl 2</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">FREYLER Stahlbau GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Draisstraße 4</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79341 Kenzingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:p.zimmermann@freyler.de">p.zimmermann@freyler.de</a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3.5 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Metallbauer/in<br />Fachrichtung<br />Konstruktionstechnik<br /><br />Verfahrensmechaniker<br />Fachrichtung<br />Beschichtungstechnik
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="349">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="349">Keller Blechtechnik GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Wöllinger Straße 3 + 5 79346 Endingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Ansprechpartner: Antje Keller</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="mailto:antje.keller@keller-blechtechnik.de">antje.keller@keller-blechtechnik.de</a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3.5 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020<br /><br /><br />01.09.2019<br />01.09.2020
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Metallbauer</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="349">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="349">Schüber Metalltechnik Gmbh</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tullastr. 24</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79369 Wyhl</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a href="http://www.schueber-gmbh.de">www.schueber-gmbh.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>mail: <a
                                                                        href="mailto:heike.schueber@schueber-gmbh.de">heike.schueber@schueber-gmbh.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel: 07642 926090</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3.5 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Metallbauer fach.<br />Konstruktionstechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="349">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="349">Metallwerkstatt Heilmann GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Kastelbergstr.19a</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79183 Waldkirch</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.metallwerkstatt-heilmann.de">www.metallwerkstatt-heilmann.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="mailto:info@metallwerkstatt-heilmann.de">info@metallwerkstatt-heilmann.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>07681/4977295</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3.5 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Metallbauer<br />Konstruktionstechnik</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="349">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="349">Maschinen und</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Metallbau Striegel GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Heerstraße 6</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79341 Hecklingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.metallbau-striegel.de">www.metallbau-striegel.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@metallbau-striegel.de">info@metallbau-striegel.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel.07644-5589850</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3.5 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <p><strong>Schreiner</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Schreiner (m/w/d)<br /><br />Anzahl 2</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="349">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="349">Kiefer &amp; Sohn GmbH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Ferdinand-Porsche-Straße 2</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79211 Denzlingen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="http://www.massgeschreinert.de/jobs">www.massgeschreinert.de/jobs</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="mailto:info@massgeschreinert.de">info@massgeschreinert.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tel. 07666/937920-0</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <p><strong>Baugewerbe</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Maurer<br />Stahlbetonbauer<br />Zimmermann</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="349">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="349">Karl Burger Hoch-,<br />Tief- und Holzbau</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Adalbert-Stifter-Strasse 2</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79183 Waldkirch</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mail: <a
                                                                        href="mailto:info@karl-burger.de">info@karl-burger.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Ansprechpartner: Silvia Burger</td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="349">Tel.: 07681-47785-11 oder<br />07681-47785-0
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja, auch kurzfristig möglich</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <p><strong>Fleischer/in Verkäufer/in</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Fleischer/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="349">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="349">Gustav Winterhalter </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Telferstraße 4 </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Elzach </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="349"><a
                                                                        href="mailto:info@metzgerei-winterhalter.de">info@metzgerei-winterhalter.de</a><br />07682/91110
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Fleischereifachverkäufer/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="349">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="349">Gustav Winterhalter </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Telferstraße 4 </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Elzach </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="349"><a
                                                                        href="mailto:info@metzgerei-winterhalter.de">info@metzgerei-winterhalter.de</a><br />07682/91110
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Fleischereifachverkäufer/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="349">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="349">Gustav Winterhalter </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tannenweg 1 </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Sexau</td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="349"><a
                                                                        href="mailto:info@metzgerei-winterhalter.de">info@metzgerei-winterhalter.de</a><br />07682/91110
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Fleischereifachverkäufer/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="349">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="349">Gustav Winterhalter </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Langestraße 46 </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Waldkirch</td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="349"><a
                                                                        href="mailto:info@metzgerei-winterhalter.de">info@metzgerei-winterhalter.de</a><br />07682/91110
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Fleischereifachverkäufer/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="349">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="349">Gustav Winterhalter </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Uffhauserstraße 3</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Freiburg</td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="349"><a
                                                                        href="mailto:info@metzgerei-winterhalter.de">info@metzgerei-winterhalter.de</a><br />07682/91110
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>Fleischereifachverkäufer/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="349">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="349">Metzgerei Sexauer</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Bahnhofstr.8</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79346 Königschaffhausen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="mailto:info@metzgerei-sexauer.de">info@metzgerei-sexauer.de</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>tel. 07642/3354</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2019<br />01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja<br />ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>1 Verkäufer/in<br />1 Fleischer/in</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <table width="257">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="257">Metzgerei u. Partyservice</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Gerhard Kaiser</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Hauptstraße 157</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>79365 Rheinhausen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td> </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Ansprechpartner: Philipp Kaiser</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>07643/265</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a
                                                                        href="mailto:Info@metzgerei-kaiser.de">Info@metzgerei-kaiser.de</a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>3 Jahre</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>01.09.2020</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <p>ja</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </DocumentMeta>
    )
}

export default JobsComponent