import React from 'react';
// import React, { useEffect } from 'react';
// import { useStyles } from '../../styles/styles';
// import { useHistory } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import CookieDeclaration from "../partials/CookieDeclaration";

function DatenschutzComponent() {

    // const history = useHistory();

    // const classes = useStyles();

    const meta = {
        title: 'Datenschutz - Kreishandwerkerschaft Emmendingen',
        description: '',
        // canonical: 'http://example.com/path/to/page',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'kreishandwerkerschaft, emmendingen, kreishandwerkerschaft emmendingen'
            }
        }
    };


    return (
        <DocumentMeta {...meta}>

            <div className="container">
                <div>
                    <h1 className="mt-2 mb-3">Datenschutzerklärung</h1>

                    <h2>&nbsp;</h2>

                    <p><strong>Information &uuml;ber die Erhebung personenbezogener Daten</strong></p>

                    <p>Wir freuen uns &uuml;ber Ihren Besuch auf unserer Website und Ihr Interesse an den dargestellten Angeboten. Wir nehmen den Schutz Ihrer pers&ouml;nlichen Daten in unserer Rolle als f&uuml;r die Datenverarbeitung Verantwortlicher sehr ernst. Internetseiten k&ouml;nnen nur angezeigt werden, wenn zuvor Daten des Besuchers, zumindest die IP-Adresse, &uuml;bertragen werden. Daher m&ouml;chten wir Sie mit dieser Datenschutzerkl&auml;rung umfassend &uuml;ber die Verarbeitung Ihrer personenbezogenen Daten im Rahmen des Besuchs dieser Website informieren. Dazu sind wir zudem gesetzlich verpflichtet. Postalisch erreichen Sie uns unter der Adresse: Kreishandwerkerschaft Emmendingen, Hebelstr.29, 79312 Emmendingen.</p>

                    <p>Wir unterliegen datenschutzrechtlich dem Bundesdatenschutzgesetz (BDSG) i.V.m. der Europ&auml;ischen Datenschutzgrundverordnung (EU-DSGVO) medienrechtlich dem TMG. Damit sind wir verpflichtet s&auml;mtliche Informationen und erfassten Daten der Besucher dieser Website zu sch&uuml;tzen und vertraulich zu behandeln. Die im Rahmen des Besuchs dieser Website gespeicherten Daten werden ausschlie&szlig;lich in der in dieser Datenschutzerkl&auml;rung beschriebenen Weise verarbeitet. Eine Verwendung personenbezogener Daten dar&uuml;ber hinaus oder eine Weitergabe an Dritte ist nicht vorgesehen.</p>

                    <p>&nbsp;</p>

                    <p><strong>Personenbezogene Daten</strong></p>

                    <p>Personenbezogene Daten sind Informationen &uuml;ber pers&ouml;nliche oder sachliche Verh&auml;ltnisse einer bestimmten oder bestimmbaren Person. Dazu geh&ouml;ren Namen und Kontaktdaten, wie Adresse, Telefonnummer, E-Mail-Adresse, sowie sensible Daten, wie zum Beispiel Informationen &uuml;ber den Gesundheitszustand aber auch Nutzungsdaten wie Ihre IP-Adresse.</p>

                    <p>&nbsp;</p>

                    <p><strong>Umfang der Verarbeitung personenbezogener Daten</strong></p>

                    <p>Wir erheben und verwenden personenbezogene Daten von Besuchern dieses Internetauftritts grunds&auml;tzlich nur, soweit dies zur Bereitstellung einer funktionsf&auml;higen Website sowie unserer Inhalte und Leistungen erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten unserer Nutzer erfolgt regelm&auml;&szlig;ig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen F&auml;llen, in denen eine vorherige Einholung einer Einwilligung aus tats&auml;chlichen Gr&uuml;nden nicht m&ouml;glich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.</p>

                    <p>&nbsp;</p>

                    <p><strong>Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten</strong></p>

                    <p>Soweit wir f&uuml;r Verarbeitungsvorg&auml;nge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.</p>

                    <p>Bei der Verarbeitung von personenbezogenen Daten, die zur Erf&uuml;llung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;b</p>

                    <p>DSGVO als Rechtsgrundlage. Dies gilt auch f&uuml;r Verarbeitungsvorg&auml;nge, die zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich sind.</p>

                    <p>Soweit eine Verarbeitung personenbezogener Daten zur Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich ist, der unser Verein unterliegt, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;c DSGVO als Rechtsgrundlage.</p>

                    <p>F&uuml;r den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen nat&uuml;rlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;d DSGVO als Rechtsgrundlage.</p>

                    <p>Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und &uuml;berwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;f DSGVO Rechtsgrundlage f&uuml;r die Verarbeitung.</p>

                    <p>&nbsp;</p>

                    <p><strong>Erfassung allgemeiner Informationen bei Aufruf unserer Website</strong></p>

                    <p>Solange Sie unsere Website lediglich informatorisch nutzen, also ohne Registrierung oder anderweitige Informations&uuml;bermittlung, erheben wir ausschlie&szlig;lich die personenbezogenen Daten, die Ihr Browser an unseren Server &uuml;bermittelt (Logfiles).</p>

                    <p>Insofern werden mit jedem Zugriff automatisch Informationen durch uns bzw. den Webspace-Provider erfasst. Diese Informationen, auch als Server-Logfiles bezeichnet, sind allgemeiner Natur und erlauben keine R&uuml;ckschl&uuml;sse auf Ihre Person.</p>

                    <p>Erfasst werden unter anderem: Name der Webseite, Sprache, Datei, Datum und Uhrzeit der Anfrage, Zeitzonendifferenz zur Greenwich Mean Time (GMT), jeweils &uuml;bertragene Datenmenge, Webbrowser und Webbrowser-Version, Betriebssystem und dessen Version, der Domainname Ihres Internet-Providers, die sogenannte Referrer-URL (sofern &uuml;bertragen (k&ouml;nnen Nutzer im Browser an- und ausschalten) jene Seite, von der aus Sie auf unser Angebot zugegriffen haben), Zugriffsstatus/HTTP-Statuscode und die IP-Adresse.</p>

                    <p>Ohne diese Daten w&auml;re es technisch teils nicht m&ouml;glich, die Inhalte der Webseite auszuliefern und darzustellen. Insofern ist die Erfassung der Daten zwingend notwendig. Dar&uuml;ber hinaus verwenden wir die anonymen Informationen f&uuml;r statistische Zwecke. Sie helfen uns bei der Optimierung des Angebots und der Technik. Wir behalten uns zudem das Recht vor, die Log-Files bei Verdacht auf eine rechtswidrige Nutzung unseres Angebotes nachtr&auml;glich zu kontrollieren.</p>

                    <p>&nbsp;</p>

                    <p><strong>Cookies</strong></p>

                    <p>Soweit wir Cookies einsetzen werden Sie beim Aufruf unserer Website durch einen Infobanner &uuml;ber die Verwendung von Cookies zu Analysezwecken informiert und auf diese Datenschutzerkl&auml;rung verwiesen. Sie k&ouml;nnen die Speicherung von Cookies &uuml;ber Ihre Browsereinstellungen unterbinden, wenn Sie dies w&uuml;nschen.</p>

                    <p>Zus&auml;tzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt (hier durch uns), bestimmte Informationen zuflie&szlig;en. Sie enthalten Informationen zum Browser, zur IP-Adresse, dem Betriebssystem und zur Internetverbindung. Cookies k&ouml;nnen keine Programme ausf&uuml;hren oder Viren auf Ihren Computer &uuml;bertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen. Diese Daten werden von uns nicht an Dritte weitergegeben oder ohne ihre Zustimmung mit personenbezogenen Daten verkn&uuml;pft.</p>

                    <p>Cookies erf&uuml;llen vor allem zwei Aufgaben. Sie helfen uns, Ihnen die Navigation durch unser Angebot zu erleichtern, und erm&ouml;glichen die korrekte Darstellung der Webseite. Sie werden nicht dazu genutzt, Viren einzuschleusen oder Programme zu starten.</p>

                    <p>Nutzer haben die M&ouml;glichkeit, unser Angebot auch ohne Cookies aufzurufen. Dazu m&uuml;ssen im Browser die entsprechenden Einstellungen ge&auml;ndert werden. Informieren Sie sich bitte &uuml;ber die Hilfsfunktion Ihres Browsers, wie Cookies deaktiviert werden. Wir weisen allerdings darauf hin, dass dadurch einige Funktionen dieser Webseite m&ouml;glicherweise beeintr&auml;chtigt werden und der Nutzungskomfort eingeschr&auml;nkt wird.</p>

                    <p>&nbsp;</p>

                    <p><strong>Verarbeitung von Daten bei Kommunikation</strong></p>

                    <p>Wenn Sie uns per E-Mail oder &uuml;ber ein Kontaktformular Informationen zuleiten, werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Anfrage zu beantworten. Hierzu werden Ihre Informationen innerhalb unserer Organisation gelesen und an die zust&auml;ndige Abteilung weitergeleitet. Die personenbezogenen Daten werden dazu gespeichert und bearbeitet. Die in diesem Zusammenhang anfallenden Daten l&ouml;schen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schr&auml;nken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.</p>

                    <p>Wenn Sie uns eine Bewerbung per E-Mail schreiben bearbeiten wir anschlie&szlig;end Ihre &uuml;bermittelten Daten ausschlie&szlig;lich zur Abwicklung des Bewerbungsverfahrens. Kommt ein Anstellungsverh&auml;ltnis mit einem Bewerber zu Stande, speichern wir die &uuml;bermittelten Daten, um die im Rahmen des Besch&auml;ftigungsverh&auml;ltnisses erforderlichen T&auml;tigkeiten durchzuf&uuml;hren. Es erhalten ausschlie&szlig;lich unsere f&uuml;r die Personalsachbearbeitung zust&auml;ndigen Mitarbeitenden Zugang zu solchen Bewerberdaten. Kommt kein Anstellungsverh&auml;ltnis zu Stande l&ouml;schen wir die Bewerbungsunterlage in der Regel zwei Monate nach Mitteilung der Absage, sofern einer L&ouml;schung kein berechtigtes Interesse entgegensteht.</p>

                    <p>Falls wir f&uuml;r einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zur&uuml;ckgreifen oder Ihre Daten f&uuml;r werbliche Zwecke nutzen m&ouml;chten, werden wir Sie untenstehend im Detail &uuml;ber die jeweiligen Vorg&auml;nge informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.</p>

                    <p>&nbsp;</p>

                    <p><strong>Einbindung von Diensten und Inhalten Dritter</strong></p>

                    <p>Es kann vorkommen, dass innerhalb dieses Onlineangebotes Inhalte Dritter, wie zum Beispiel Videos von YouTube oder Facebook, Kartenmaterial von Google-Maps, RSS-Feeds oder Grafiken von anderen Webseiten eingebunden werden. Dies setzt immer voraus, dass die Anbieter dieser Inhalte (nachfolgend bezeichnet als &bdquo;Dritt-Anbieter&ldquo;) die IP-Adresse der Nutzer wahrnehmen. Denn ohne die IP-Adresse, k&ouml;nnten sie die Inhalte nicht an den Browser des jeweiligen Nutzers senden. Die IP-Adresse ist damit f&uuml;r die Darstellung dieser Inhalte erforderlich. Wir bem&uuml;hen uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Jedoch haben wir keinen Einfluss darauf, falls die Dritt-Anbieter die IP-Adresse z.B. f&uuml;r statistische Zwecke speichern. Soweit uns dies bekannt ist, kl&auml;ren wir die Nutzer dar&uuml;ber auf.</p>

                    <p>&nbsp;</p>

                    <p><strong>Ihre Rechte</strong></p>

                    <p>Sie haben gegen&uuml;ber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:</p>

                    <p>Recht auf Auskunft, Art. 15 EU-DSGVO</p>

                    <p>Recht auf Berichtigung oder L&ouml;schung, Art. 16, 17 EU-DSGVO</p>

                    <p>Recht auf Einschr&auml;nkung der Verarbeitung, Art. 18 EU-DSGVO</p>

                    <p>Recht auf Daten&uuml;bertragbarkeit, Art. 20 EU-DSGVO</p>

                    <p>Recht auf Widerspruch gegen die Verarbeitung, Art. 21 EU-DSGVO</p>

                    <p>Um eine Datensperre jederzeit ber&uuml;cksichtigen zu k&ouml;nnen, ist es erforderlich, die Daten f&uuml;r Kontrollzwecke in einer Sperrdatei vorzuhalten. Besteht keine gesetzliche Archivierungspflicht, k&ouml;nnen Sie auch die L&ouml;schung der Daten verlangen. Anderenfalls sperren wir die Daten, sofern Sie dies w&uuml;nschen.</p>

                    <p>&nbsp;</p>

                    <p><strong>Datenschutzbeauftragter </strong></p>

                    <p>Freiburger Datenschutzgesellschaft mbH</p>

                    <p>Gesch&auml;ftsf&uuml;hrer: Herr Rechtsanwalt J&ouml;rg Leuchtner</p>

                    <p>Luisenstr. 5</p>

                    <p>D - 79098 Freiburg</p>

                    <p>Tel.: + 49 (0) 761 - 21716550</p>

                    <p>Fax.: + 49 (0) 761 - 21716551</p>

                    <p>E-Mail: info@freiburger-datenschutzgesellschaft.de</p>

                    <p>Internet: <a href="http://www.freiburger-datenschutzgesellschaft.de/">www.freiburger-datenschutzgesellschaft.de</a></p>

                    <p>&nbsp;</p>

                    <p>Sie haben die M&ouml;glichkeit unseren Datenschutzbeauftragten zu kontaktieren:</p>

                    <p>Herr Rechtsanwalt J&ouml;rg Leuchtner</p>

                    <p>Luisenstr. 5</p>

                    <p>D - 79098 Freiburg</p>

                    <p>Tel.: + 49 (0) 761 - 21716550</p>

                    <p>Fax.: + 49 (0) 761 - 21716551</p>

                    <p>E-Mail: info@freiburger-datenschutzgesellschaft.de</p>

                    <p>&nbsp;</p>

                    <p><strong>Aufsichtsbeh&ouml;rde</strong></p>

                    <p>Sie haben das Recht, sich bei der zust&auml;ndigen Datenschutz-Aufsichtsbeh&ouml;rde (Aufsichtsbeh&ouml;rde) &uuml;ber die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren. Weitere Informationen zum Thema Datenschutz in der Bundesrepublik Deutschland finden Sie zudem unter&nbsp;<a href="http://www.bfd.bund.de/">www.bfd.bund.de</a>.</p>

                    <p><strong>Datenminimierung und Speicherbegrenzung</strong></p>

                    <p>Personenbezogene Daten speichern wir gem&auml;&szlig; den Grunds&auml;tzen der Datenminimierung und Speicherbegrenzung nur so lange, wie es erforderlich ist oder vom Gesetzgeber her vorgeschrieben wird (gesetzliche Speicherfrist). Entf&auml;llt der Zweck der erhobenen Informationen oder endet die Speicherfrist, sperren oder l&ouml;schen wir die Daten.</p>

                    <p>&nbsp;</p>

                    <p><strong>&Auml;nderung der Datenschutzhinweise</strong></p>

                    <p>Aufgrund der stetigen Entwicklung neuer Internet-Technologien und &Auml;nderungen geltender Datenschutzbestimmungen wird unsere Datenschutzerkl&auml;rung im erforderlichen Umfang aktualisiert. Es gilt immer diejenige Datenschutzerkl&auml;rung, die zum Zeitpunkt des jeweiligen Besuches auf unserer Internetseite abrufbar ist.</p>
                    

                </div>

                <CookieDeclaration />

                <div>
                <script 
                    id="CookieDeclaration" 
                    src="https://consent.cookiebot.com/96e97c27-353d-41a5-b05a-0aab04464211/cd.js" 
                    data-culture="de" 
                    type="text/javascript" 
                    async>
                </script>    

                </div>

            </div>
        </DocumentMeta>
    )
}

export default DatenschutzComponent