import * as React from 'react';
// import { useHistory, Link } from 'react-router-dom';
// import { useStyles } from '../../styles/styles';

const Partner = () => {

//   const classes = useStyles();

  return (
    <div className="container container--large partner">
        <h4>Unsere Partner</h4>
        <div className="grid grid--four-columns">
            
            <div className="">
                <a className="" href="https://www.ikk-classic.de/" target="_blank" rel="noreferrer">
                    <img className="img-fluid" src="/img/partner/ikk.jpeg" alt="ikk" />
                </a>    
            </div>
            <div className="">
                <a className="" href="https://www.mewa.de/" target="_blank" rel="noreferrer">
                    <img className="img-fluid" src="/img/partner/MEWA.jpeg" alt="MEWA" />
                </a>    
            </div>
            <div className="">
                <a className="" href="https://www.signal-iduna.de/" target="_blank" rel="noreferrer">
                    <img className="img-fluid" src="/img/partner/signal-iduna-logo.jpeg" alt="signal iduna" />
                </a>    
            </div>

            <div className="">
                <a className="" href="https://www.hwk-freiburg.de/de" target="_blank" rel="noreferrer">
                    <img className="img-fluid" src="/img/partner/HWK_FR.jpeg" alt="HWK-FR" />
                </a>    
            </div>


        </div>
    </div>
  );
};
export default Partner;