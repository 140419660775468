import React from 'react';
// import React, { useEffect } from 'react';
// import { useStyles } from '../../styles/styles';
// import { useHistory } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import DocumentMeta from 'react-document-meta';

function AboutUsComponent() {

//   const history = useHistory();

//   const classes = useStyles();

  // const gotToDashboard = (e, url) => {
  //   history.push(url);
  // }

  const meta = {
    title: 'Über uns - Kreishandwerkerschaft Emmendingen',
    description: '',
    // canonical: 'http://example.com/path/to/page',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'kreishandwerkerschaft, emmendingen, kreishandwerkerschaft emmendingen'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>        
      <div className="container">
          <h1 className="mt-2 mb-3">Über die Kreishandwerkerschaft Emmendingen</h1>
          <h2>Das können wir für Sie tun</h2>
          <div class="">
              <p>Folgende Bereiche bilden den Schwerpunkt unserer Arbeit:</p>
              <ul>
                  <li>
                      <p>Ausbildungsberatung (Ausfüllen der Lehrverträge, Akürzung der Lehrzeit etc.)</p>
                  </li>
                  <li>
                      <p>Berichtshefte für die einzelnen Handwerksberufe beschaffen</p>
                  </li>
                  <li>
                      <p>Gemeinsame Freisprechungsfeier der elf Innungen organisieren und veranstalten</p>
                  </li>
                  <li>
                      <p>Behörden im Kreis Emmendingen durch Gespräche und Informationen unterstützen</p>
                  </li>
                  <li>
                      <p>Geschäftsführungen von Handwerksinnungen</p>
                  </li>
                  <li>
                      <p>Jährliche gemeinsame Aktionen in Zusammenarbeit mit den Arbeitsämtern Emmendingen und Waldkirch</p>
                  </li>
                  <li>
                      <p>Mahnverfahren durchführen</p>
                  </li>
                  <li>
                      <p>Ausstellungen organisieren sowie Mithilfe bei den einzelnen Innungen</p>
                  </li>
                  <li>
                      <p>Informationen über einzelne Tarife an die Mitglieder der Innungen weitergeben</p>
                  </li>
                  <li>
                      <p>Vermittlung sowie Mithilfe bei sachverständigen Fragen</p>
                  </li>
                  <li>
                      <p>Beratung für Weiterbildung der einzelnen Berufe (Meisterschule, Kurse)</p>
                  </li>
                  <li>
                      <p>Vermittlung und Verfolgung bei unzulässiger Handwerksausübung (Schwarzarbeit)</p>
                  </li>
                  <li>
                      <p>Organisation der Altmeisterehrung (goldene bzw. diamantene Meisterbriefe)</p>
                  </li>
              </ul>
              <p>Die aufgeführten Leistungen sind nur ein Ausschnitt aus der Leistungspalette der Kreishandwerkerschaft
                  Emmendingen und erheben keinen Anspruch auf Vollständigkeit.</p>
          </div>
          <h2 className="mt-2">Berufsbildung</h2>
          <div class="">
              <p>Wir kümmern uns um den Nachwuchs (Betreuung der Lehrlingsausbildung, Schaffung von überbetrieblichen
                  Ausbildungsstätten, Organisation der Prüfungen in den Innungen usw.) und wir sorgen durch zahlreiche
                  Weiterbildungsangebote dafür, dass Betriebsinhaber, Unternehmerfrauen und Mitarbeiter ihre Kenntnisse auf
                  technischem und kaufmännischem Gebiet aktualisieren können.</p>
          </div>
          <h2 className="mt-2">Beratung</h2>
          <div class="">
              <p>Wir informieren Sie ständig über neueste Entwicklungen und beraten Sie in allen Fragen zu den Bereichen Technik,
                  Wirtschaft, Umweltschutz. Außerdem beraten wir Sie in Rechtsfragen, welche Ihren Betrieb betreffen. Auf Wunsch
                  entwerfen unsere Tarifexperten für Sie individuelle Arbeitsverträge.</p>
          </div>
          <h2 className="mt-2">Service</h2>
          <div class="">
              <p>Wir sorgen durch Werbung und Öffentlichkeitsarbeit dafür, dass die hohe Qualität handwerklicher Leistung immer
                  wieder demonstriert wird.</p>
          </div>
          <h2 className="mt-2">Interessenvertretung</h2>
          <div class="">
              <p>Wir engagieren uns im Rahmen von kommunalpolitischen Aktivitäten und bringen unseren Sachverstand durch Gutachten
                  und Stellungnahmen ein.</p>
          </div>

        </div>
    </DocumentMeta>
  )
}

export default AboutUsComponent