import {BrowserRouter} from 'react-router-dom';
import Routes from "../Routes";
// import Header from './layouts/Header';
// import Header3 from './layouts/Header4';
import Header3 from './layouts/Header3';
// import Header2 from './layouts/Header2';
import Partner from './layouts/Partner';
import Footer from './layouts/Footer';

function App() {
  return (
   <BrowserRouter>
      <Header3 />
      {/* <Header /> */}
      {/* <Header2 /> */}
      <div className="App">
      <Routes/>
      </div>
      <Partner />
      <Footer />
   </BrowserRouter>
  );
}

export default App;
