import React from 'react';
// import React, { useEffect } from 'react';
// import Button from '@mui/material/Button';
// import { useStyles } from '../../styles/styles';
// import { useHistory } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import DocumentMeta from 'react-document-meta';

function TeamComponent() {

    // const history = useHistory();

    // const classes = useStyles();

    // const gotToDashboard = (e, url) => {
    //   history.push(url);
    // }

    const meta = {
        title: 'Team - Kreishandwerkerschaft Emmendingen',
        description: '',
        // canonical: 'http://example.com/path/to/page',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'kreishandwerkerschaft, emmendingen, kreishandwerkerschaft emmendingen'
            }
        }
    };

    return (
        <DocumentMeta {...meta}>
            <div className="container mb-2">
                <h1 className="mt-2 mb-3">Team</h1>
                <div className="grid grid--two-to-one">

                    <div className="">
                        <div class="">
                            <p>
                                <strong>Kreishandwerksmeister</strong><br />
                                <strong>Martin Schubnell</strong><br />
                            </p>
                        </div>
                    </div>
                    <div className="">
                    </div>

                    <div className="">
                        <div class="">
                            <p>
                                <strong>Geschäftsführung </strong><br />
                                <strong>Sonja Köchlin </strong><br />
                                Bürozeiten:<br />
                                von Montag bis Freitag in der Zeit von 8:00 – 12:00 Uhr. <br />
                                Tel.: 07641 &#8211; 47331 <br />
                                Fax: 07641 &#8211; 931842<br />
                                Mail: <a href="mailto:info@kh-em.de">info@kh-em.de</a>
                            </p>
                        </div>
                    </div>
                    <div className="">
                        <img className="img-fluid" src="/img/Sonja-Köchlin.jpeg" alt="Sonja Köchlin" />
                    </div>
                </div>
            </div>
        </DocumentMeta>
    )
}

export default TeamComponent
