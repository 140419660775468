import React from 'react';
// import { AppBar, CssBaseline, Toolbar, Typography, Button } from '@mui/material';
import { useStyles } from '../../styles/styles';
// import Button from '@mui/material/Button';

// import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

export default function Header3(props) {
    // const history = useHistory();

    const classes = useStyles();

    // const dispatch = useDispatch();

    // const gotToDashboard = (e, url) => {
    //     history.push(url);
    // }

    /**
     * Simulate a click event.
     * @public
     * @param {Element} elem  the element to simulate a click on
     */
    var simulateClick = function (elem) {
        // Create our event (with options)
        var evt = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        });
        // If cancelled, don't dispatch our event
        var canceled = !elem.dispatchEvent(evt);
    };

    // const triggerClick = (e, url) => {
    const triggerClick = (e) => {
        // history.push(url);
        let el = document.getElementById('main-navigation-toggle-close');
        simulateClick(el);
    }


    return (
        <header className="site-header">
        <div className="main-navigation-wrapper">
            <Link to="/home" className="header__logo--link" onClick={e => triggerClick(e)}>
                <picture>
                    <source media="(min-width: 190em)" srcSet="/img/KreishandwerkerschaftEM-Logo.jpeg" />
                    <img className="header__logo" src="/img/KreishandwerkerschaftEM-Logo.jpeg" alt="Logo" />
                </picture>
            </Link>
            <nav className="main-navigation" aria-labelledby="main-navigation-label">
                <span id="main-navigation-label" className="visually-hidden">Hauptnavigation</span>
  
                <div className="main-navigation__mobile-top-nav">
  
                    <button className="main-navigation__toggle" data-a11y-toggle="main-navigation__dropdown" type="button">
                        <span>
                            <svg className="icon icon--open" aria-labelledby="main-navigation-toggle-open" focusable="false">
  
                                <title id="main-navigation-toggle-open">öffnen</title>
                                <use xlinkHref="#bars-light" />
                            </svg>
                            <svg className="icon icon--close" aria-labelledby="main-navigation-toggle-close"
                                focusable="false">
  
                                <title id="main-navigation-toggle-close">schließen</title>
                                <use xlinkHref="#times-light" />
                            </svg>
                        </span>
                    </button>
                </div>
  
                <div id="main-navigation__dropdown" className="main-navigation__dropdown">
                    <ul id="main-navigation__list" className="main-navigation__list connected-toggles">
                        <li className="main-navigation__list-item">
                            <Link to="/home" className={classes.link} onClick={e => triggerClick(e)}> Home</Link>
                        </li>
                        <li className="main-navigation__list-item">
                            <Link to="/ueber_uns" className="main-navigation__link" onClick={e => triggerClick(e)}> Über uns</Link>
                        </li>
                        <li className="main-navigation__list-item">
                            <Link to="/vorstand" className="main-navigation__link" onClick={e => triggerClick(e)}> Vorstand</Link>
                        </li>
                        <li className="main-navigation__list-item">
                            <Link to="/team" className={classes.link} onClick={e => triggerClick(e)}> Team</Link>
                        </li>
                        <li className="main-navigation__list-item">
                            <Link to="/innungen" className={classes.link} onClick={e => triggerClick(e)}> Innungen</Link>
                        </li>
                        <li className="main-navigation__list-item">
                            <Link to="/aktuelles" className={classes.link} onClick={e => triggerClick(e)}> Aktuelles</Link>
                        </li>
                        <li className="main-navigation__list-item">
                            <Link to="/seminare" className={classes.link} onClick={e => triggerClick(e)}> Seminare</Link>
                        </li>
                        {/* <li className="main-navigation__list-item">
                            <Link to="/jobs" className={classes.link} onClick={e => triggerClick(e)}> Jobs</Link>
                        </li> */}

                        <li className="main-navigation__list-item main-navigation__mobillist-item">
                            <Link to="/impressum" className={classes.link} onClick={e => triggerClick(e)}> Impressum</Link>
                        </li>
                        <li className="main-navigation__list-item main-navigation__mobillist-item">
                            <Link to="/datenschutz" className={classes.link} onClick={e => triggerClick(e)}> Datenschutzerkl&auml;rung</Link>
                        </li>
  
                    </ul>
                </div>
            </nav>
        </div>
    </header>
    );
}
