import * as React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
// import Container from '@mui/material/Container';
// import Button from '@mui/material/Button';
// import MenuItem from '@mui/material/MenuItem';
import { useHistory, Link } from 'react-router-dom';
import { useStyles } from '../../styles/styles';

declare var Cookiebot: any;

const Footer = () => {

    const classes = useStyles();

    const CbSettings = (e: any) => {
        //   javascript:Cookiebot.renew();
        
        if(typeof Cookiebot !== "undefined") {
            Cookiebot.renew();
        }

        // if(showingInfoWindow === false) {
        //   setShowingInfoWindow(true);
        //   // onChangeInfoWindow(props.data.id);
        // } else {
        //   setShowingInfoWindow(false);
        // }
    };


    return (
        <footer className="footer">
            <div className="container container--large">
                <div>
                    <h3 className="footer__headline">
                        Kontakt
                    </h3>
                    <address className="footer__adress">
                        Kreishandwerkerschaft Emmendingen<br />
                        Hebelstraße 29<br />
                        79312 Emmendingen<br />
                        <br />
                        E-Mail: info@kh-em.de<br />
                        Tel:  07641-47331<br />
                        Fax:  07641 – 931842
                    </address>
                </div>
                <div>
                    <h3 className="footer__headline hide_mobile">
                        Öffnungszeiten
                    </h3>
                    <address className="footer__adress">
                        Von Montag bis Freitag<br />
                        in der Zeit von<br />
                        08.00 Uhr bis 12.00 Uhr
                        <br /><br />
                    </address>
                    <p>Ihre Ansprechpartnerin: Sonja Köchlin</p>
                </div>
                <nav className="footer__navigation">
                    <h3 className="footer__headline">
                        Navigation
                    </h3>
                    <ul className="footer__upper-navigation-list">
                        <li className="footer__upper-navigation-list-item">
                            <Link to="/impressum"> Impressum</Link>
                        </li>
                        <li className="footer__upper-navigation-list-item">
                            <Link to="/datenschutz"> Datenschutzerkl&auml;rung</Link>
                        </li>
                        <li className="footer__upper-navigation-list-item">
                            <button onClick={CbSettings} className="btn btn-white btn-link">Cookie Einstellungen</button>
                        </li>
                    </ul>

                    <div className="mt-1 mb-1">
                        <a className="" href="https://www.facebook.com/Kreishandwerkerschaft-Emmendingen-185046924874706/" target="_blanc">
                            <img className="img-fluid" src="/img/flogo50-e47905df.jpeg" alt="" />
                        </a>
                    </div>

                    <div className="">
                        <a className="" href="https://handwerk.de/erfuellung/" target="_blanc">
                            <img className="img-fluid" src="/img/HAN_Logo_RGB_47mm_300dpi_zentriert-b7e61366.jpeg" alt="" />
                        </a>
                    </div>

                </nav>
            </div>
        </footer>
    );
};
export default Footer;